import React from 'react';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useParams } from 'react-router-dom';
import BenefitPayrollReportPageModule from '../../module/benefits/payrollReport/BenefitPayrollReportPage';
import { useTenant } from 'probonio-shared-ui/module/me';
import { ReportDiagram, ReportDiagramData } from '../../module/benefits';
import { DateTime } from 'luxon';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useMemo } from 'react';

export function useBenefitReport(benefit: BenefitDTOBenefitEnum): {
  reportMonth: DateTime;
  report?: ReportDiagramData;
  isLoading: boolean;
} {
  const reportMonth = useMemo(() => DateTime.now().minus({ months: 1 }).startOf('month'), []);

  const queryKey = ['benefits', benefit.toLowerCase(), 'reports', reportMonth.toFormat('yyyy-MM'), 'getReport'];

  const apiFunction = (tenantId: string) => {
    const month = reportMonth.toFormat('yyyy-MM');
    switch (benefit) {
      case BenefitDTOBenefitEnum.Lunch:
        return apis.lunch.getReport({ tenantId, month }).then(res => res.data);
      case BenefitDTOBenefitEnum.Mobility:
        return apis.mobility.getReport({ tenantId, month }).then(res => res.data);
      default:
        return Promise.resolve(undefined);
    }
  };

  const { data: report, isLoading } = useTenantQuery(queryKey, apiFunction, {
    placeholderData: previousData => previousData,
    enabled: benefit === BenefitDTOBenefitEnum.Lunch || benefit === BenefitDTOBenefitEnum.Mobility,
  });

  return useMemo(
    () => ({
      reportMonth,
      report,
      isLoading,
    }),
    [isLoading, report, reportMonth],
  );
}

interface SpecialBenefitReportPageProps {
  benefit: BenefitDTOBenefitEnum;
  activeBenefits: BenefitDTOBenefitEnum[];
}

const SpecialBenefitReportPage: React.FC<SpecialBenefitReportPageProps> = ({ benefit, activeBenefits }) => {
  const { reportMonth, report, isLoading } = useBenefitReport(benefit);

  const showReceiptsWarning = report && report.validatedCount < report.validatedMax;

  return (
    <BenefitPayrollReportPageModule
      reportBenefit={benefit}
      activeBenefits={activeBenefits}
      menu={<ReportDiagram report={report} isLoading={isLoading} reportMonth={reportMonth} />}
      showReceiptsWarning={showReceiptsWarning}
    />
  );
};

export const BenefitPayrollReportPage: React.FC = () => {
  const { benefit: benefitName } = useParams<{ benefit: string }>();
  const reportBenefit = benefitName!.toUpperCase() as BenefitDTOBenefitEnum;
  const { tenant } = useTenant();

  if (!tenant) {
    return null;
  }

  if (reportBenefit === BenefitDTOBenefitEnum.Lunch || reportBenefit === BenefitDTOBenefitEnum.Mobility) {
    return <SpecialBenefitReportPage benefit={reportBenefit} activeBenefits={tenant.activeBenefits!} />;
  }

  return <BenefitPayrollReportPageModule reportBenefit={reportBenefit} activeBenefits={tenant.activeBenefits!} />;
};
