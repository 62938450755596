import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { InvoiceDTO } from 'probonio-shared-ui/api';
import { useTranslation } from 'react-i18next';

export const InvoiceStatus: React.FC<{ invoice: InvoiceDTO }> = ({ invoice }) => {
  const { t } = useTranslation('tenantModule');
  const status = invoice.status;
  let statusIcon = <CheckCircleOutlineIcon color="success" />;
  if (invoice.status === 'VOIDED') {
    statusIcon = <HighlightOffIcon color="error" />;
  } else if (invoice.status === 'OPEN' || invoice.status === 'DRAFT') {
    statusIcon = <AccessTimeIcon color="primary" />;
  }

  return (
    <Grid container gap={0.5} alignItems="center">
      {statusIcon}
      <Typography variant="inherit" component="span">
        {t(`invoices.invoice.status.${status}`)}
      </Typography>
    </Grid>
  );
};
