import { BenefitDTOBenefitEnum, TenantBenefitSortingDTO } from 'probonio-shared-ui/api';
import { CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DragAndDropList from '../../../component/dragAndDrop/DragAndDropList';
import { BENEFIT_ICONS } from '../../benefits/BenefitIcon';

interface BenefitDragAndDropListProps {
  benefitSorting: TenantBenefitSortingDTO[];
  onChange: (items: TenantBenefitSortingDTO[]) => void;
}
const BenefitDragAndDropList: React.FC<BenefitDragAndDropListProps> = ({ benefitSorting, onChange }) => {
  const { t } = useTranslation();

  const benefitDraggables = useMemo(() => {
    return benefitSorting.map(val => {
      const isCustomBenefit = val.customBenefitDetails && val.benefit && val.benefit === BenefitDTOBenefitEnum.Custom;
      const title = isCustomBenefit ? val.customBenefitDetails!.title : t(`common:benefit.${val.benefit}`);
      const icon = isCustomBenefit ? CUSTOM_BENEFIT_ICONS[val.customBenefitDetails!.iconName] : BENEFIT_ICONS[val.benefit];
      const id = isCustomBenefit ? val.customBenefitId! : val.benefit;

      return { title, icon, id, data: val };
    });
  }, [benefitSorting, t]);

  const handleChange = useCallback(
    (items: TenantBenefitSortingDTO[]) => {
      onChange(items);
    },
    [onChange],
  );

  return <DragAndDropList items={benefitDraggables} onChange={handleChange} />;
};

export default BenefitDragAndDropList;
