import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalculateIcon from '@mui/icons-material/Calculate';
import CircleIcon from '@mui/icons-material/Circle';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HomeIcon from '@mui/icons-material/Home';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  Chip,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  SvgIconProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { apis } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useNewDialogState } from '../../component/dialog';
import { MainDrawerProvider, useMainDrawer } from '../../component/drawer/MainDrawerContext';
import { SupportDialog } from '../../component/support';
import { fontWeights } from '../../theme';
import { NavBenefitList } from '../benefits';
import { CustomBenefitList } from '../benefits/nav/CustomBenefitList';
import { BenefitButtonRenderer } from '../benefits/nav/NavBenefitList';
import { SendMailDialog } from '../benefits/nav/SendMailDialog';
import { useTutorialContext } from '../tenant/Tutorial/tutorialContext';
import { MainMenuDrawer } from './MainMenuDrawer';
import { ReferralDialog } from './ReferralDialog';
import { UserEditDialog } from './UserEditDialog';
import { PROBONIO_IMPRINT } from '../../constants';

const MainMenuItem: React.FC<{
  icon?: React.ComponentType<Pick<SvgIconProps<'svg', {}>, 'color'>>;
  title: string;
  color?: SvgIconProps['color'];
  selected?: boolean;
  onClick: () => void;
  badgeLabel?: string;
  dataTestId?: string;
  className?: string;
  subMenu?: React.ReactNode;
}> = React.memo(({ icon, title, color, selected, badgeLabel, dataTestId, className, subMenu, onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xl'));
  const { handleClose } = useMainDrawer();

  const handleClick = useCallback(() => {
    if (isMobile && !subMenu) {
      handleClose();
    }
    onClick();
  }, [handleClose, isMobile, onClick, subMenu]);

  const IconComponent = icon || MoreHorizIcon;

  return (
    <>
      <ListItem
        dense
        disablePadding
        className={className}
        aria-expanded={subMenu ? selected : undefined}
        aria-controls={subMenu ? 'sub-menu' : undefined}
      >
        <ListItemButton selected={selected} onClick={handleClick} data-test-id={dataTestId}>
          <ListItemIcon sx={{ minWidth: 40 }}>
            <IconComponent color={color || 'primary'} />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography variant="body2" color={`text.${color || 'secondary'}`} component="span">
              {title}
            </Typography>
          </ListItemText>
          {badgeLabel && <Chip size="small" variant="outlined" color="error" label={badgeLabel} sx={{ pointerEvents: 'none' }} />}
          {subMenu &&
            !badgeLabel &&
            (selected ? <ExpandLessIcon color={color || 'primary'} /> : <ExpandMoreIcon color={color || 'primary'} />)}
        </ListItemButton>
      </ListItem>
      {subMenu && (
        <Collapse in={selected} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subMenu}
          </List>
        </Collapse>
      )}
    </>
  );
});

const DotIcon: React.FC<{ large?: boolean }> = ({ large }) => {
  return <CircleIcon color="primary" sx={{ fontSize: large ? '0.6em' : '0.3em', ml: large ? 0.8 : 1, transition: '0.1s' }} />;
};

const SubMenuItem: React.FC<{ title: string; to: string; hasSubpages?: boolean }> = ({ title, to, hasSubpages }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xl'));
  const { handleClose } = useMainDrawer();

  const handleClick = useCallback(() => {
    if (isMobile) {
      handleClose();
    }
    navigate(to);
  }, [handleClose, isMobile, navigate, to]);

  const active = location.pathname === to || (hasSubpages && location.pathname.startsWith(`${to}/`));

  return (
    <ListItem dense disablePadding>
      <ListItemButton onClick={handleClick} data-test-id={`submenu${to.replaceAll('/', '-')}`}>
        <ListItemIcon sx={{ minWidth: 40 }}>
          <DotIcon large={active} />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ color: 'text.secondary', fontWeight: active ? 'bold' : undefined }}>{title}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

const BenefitMenuItem: BenefitButtonRenderer = React.memo(
  ({ onClick, title, active, icon, selected, badgeLabel, dataTestId, subMenuItems }) => {
    let subMenu;
    if (subMenuItems?.length) {
      subMenu = subMenuItems.map(item => <SubMenuItem key={item.to} title={item.title} to={item.to} hasSubpages={item.hasSubpages} />);
    }

    return (
      <MainMenuItem
        icon={icon}
        title={title}
        color={active ? undefined : 'disabled'}
        selected={selected}
        onClick={onClick}
        badgeLabel={badgeLabel}
        dataTestId={dataTestId}
        subMenu={active ? subMenu : undefined}
      />
    );
  },
);

const MenuLink: React.FC<{ title: string; href: string }> = React.memo(({ title, href }) => {
  return (
    <a href={href} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">
      <Typography variant="body2" component="span" color="text.secondary" fontWeight={fontWeights.bold} sx={{ opacity: 0.75 }}>
        {title}
      </Typography>
    </a>
  );
});

export const BenefitsDrawer: React.FC = React.memo(() => {
  const { t } = useTranslation('navigationModule');
  const navigate = useNavigate();
  const location = useLocation();
  const { dialogState: sendMailDialogState, handleOpen: handleOpenSendMail } = useNewDialogState();
  const { dialogState: supportDialogState, handleOpen: handleOpenSupport } = useNewDialogState();
  const userEditDialog = useNewDialogState();
  const referralCodeDialog = useNewDialogState();
  const {
    drawerState: { open, handleOpen: handleOpenTutorial },
    steps: { openCount },
  } = useTutorialContext();
  const { handleClose } = useMainDrawer();

  const withMessage = useWithMessage();
  const { data: latestTerms } = useQuery({
    queryKey: ['legalTerms', apis.legalTerms.getLatestLegalTerms.name],
    queryFn: () =>
      apis.legalTerms
        .getLatestLegalTerms({ legalType: 'TENANT_TERMS' })
        .then(res => res.data)
        .catch(e => {
          if (e instanceof AxiosError && e.response?.status === 404) {
            // swallow if no terms yet
          } else {
            withMessage(e as Error);
          }
          return null;
        }),
  });
  const handleHome = useCallback(() => {
    navigate('/');
  }, [navigate]);
  const handleEmployees = useCallback(() => {
    navigate('/employees');
  }, [navigate]);
  const handleCalculator = useCallback(() => {
    navigate('/calculator');
  }, [navigate]);
  const handleSettings = useCallback(() => {
    navigate('/settings');
  }, [navigate]);
  const handleLogout = useCallback(() => {
    navigate('/logout');
  }, [navigate]);
  const handleManual = useCallback(() => {
    window.open('https://support.probonio.de/hc');
  }, []);

  const handleCloseSidebar = useCallback(() => {
    handleClose();
  }, [handleClose]);

  return (
    <>
      <MainMenuDrawer
        footer={
          <>
            {!open && (
              <Box paddingX={3.5} paddingY={1} display="flex" justifyContent="space-between">
                <MenuLink title={t('menuItem.siteNotice')} href={PROBONIO_IMPRINT} />
                {latestTerms && (
                  <Link to="/legalTerms" onClick={handleCloseSidebar} style={{ textDecoration: 'none' }}>
                    <Typography
                      variant="body2"
                      component="span"
                      data-test-id="legal-terms-link"
                      color="text.secondary"
                      fontWeight={fontWeights.bold}
                      sx={{ opacity: 0.75, textDecoration: 'none', cursor: 'pointer' }}
                    >
                      {t('legalTerms:title')}
                    </Typography>
                  </Link>
                )}
                <MenuLink title={t('menuItem.dataPrivacy')} href="https://www.probonio.de/datenschutz" />
              </Box>
            )}
          </>
        }
      >
        {!open && (
          <Box>
            <List subheader={<ListSubheader>{t('menuHeader.overview')}</ListSubheader>}>
              <MainMenuItem
                dataTestId="to-dashboard-button"
                icon={HomeIcon}
                title={t('home')}
                selected={location.pathname === '/'}
                onClick={handleHome}
              />
              <MainMenuItem
                dataTestId="to-homepage-button"
                icon={PeopleIcon}
                title={t('usersModule:title')}
                selected={location.pathname.startsWith('/employees')}
                onClick={handleEmployees}
              />
              <MainMenuItem
                dataTestId="to-calculator-button"
                icon={CalculateIcon}
                title={t('equivalenceCalculator:title')}
                selected={location.pathname.startsWith('/calculator')}
                onClick={handleCalculator}
              />
            </List>
            <List subheader={<ListSubheader>{t('menuHeader.benefits')}</ListSubheader>}>
              <NavBenefitList
                renderer={BenefitMenuItem}
                onSupportDialogOpen={handleOpenSupport}
                onSendMailDialogOpen={handleOpenSendMail}
              />
            </List>
            <List subheader={<ListSubheader>{t('menuHeader.customBenefits')}</ListSubheader>}>
              <CustomBenefitList renderer={BenefitMenuItem} />
            </List>
            <List subheader={<ListSubheader>{t('menuHeader.application')}</ListSubheader>}>
              <MainMenuItem
                icon={LightbulbIcon}
                title={t('tutorialModule:buttonOpenDrawer')}
                onClick={handleOpenTutorial}
                badgeLabel={openCount ? `${openCount}` : undefined}
                className="tutorial-opener"
                dataTestId="toggle-tutorial-menu-button"
              />
              <MainMenuItem icon={MenuBookIcon} title={t('menuItem.manual')} onClick={handleManual} />
              <MainMenuItem icon={ContactSupportIcon} title={t('menuItem.contactSupport')} onClick={handleOpenSupport} />
              <MainMenuItem
                dataTestId="referral-menu-button"
                icon={HandshakeIcon}
                title={t('menuItem.referral')}
                onClick={referralCodeDialog.handleOpen}
              />
              <MainMenuItem
                icon={SettingsIcon}
                title={t('menuItem.tenantSettings')}
                selected={location.pathname === '/settings'}
                onClick={handleSettings}
              />
              <MainMenuItem icon={AccountCircleIcon} title={t('menuItem.userProfile')} onClick={userEditDialog.handleOpen} />
              <MainMenuItem icon={LogoutIcon} title={t('logout')} onClick={handleLogout} />
            </List>
          </Box>
        )}
      </MainMenuDrawer>
      <SupportDialog dialogState={supportDialogState} />
      <SendMailDialog dialogState={sendMailDialogState} />
      <UserEditDialog dialogState={userEditDialog.dialogState} />
      <ReferralDialog dialogState={referralCodeDialog.dialogState} />
    </>
  );
});

export const BenefitsDrawerWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <MainDrawerProvider>
      <Box display="flex">
        <BenefitsDrawer />
        <Box flexGrow={1}>{children}</Box>
      </Box>
    </MainDrawerProvider>
  );
};
