import { MRT_ColumnDef } from 'material-react-table';
import { InvoiceDTO } from 'probonio-shared-ui/api';
import { SortByDef } from '../../component/table/sortMapper';
import { InvoiceStatus } from './InvoiceStatus';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useInvoiceColumns(): MRT_ColumnDef<InvoiceDTO & SortByDef>[] {
  const { t } = useTranslation('tenantModule');
  return useMemo<MRT_ColumnDef<InvoiceDTO & SortByDef>[]>(
    () => [
      {
        accessorKey: 'date',
        header: t('invoices.invoice.date'),
        sortBy: ['date'],
        Cell: ({ cell }) => (cell.renderValue() ? t('common:date', { date: new Date(cell.renderValue() as string) }) : null),
      },
      {
        accessorKey: 'invoiceType',
        header: t('invoices.invoice.type.title'),
        Cell: ({ cell }) => {
          return t(`invoices.invoice.type.${(cell.renderValue() as Array<string>)[0]}`, { defaultValue: cell.getValue() });
        },
      },
      {
        accessorKey: 'accountingInvoiceNumber',
        header: t('invoices.invoice.accountingInvoiceNumber'),
      },
      {
        accessorKey: 'amount',
        header: t('invoices.invoice.amount'),
        Cell: ({ cell }) => t('common:money', { money: cell.getValue() }),
      },
      {
        accessorKey: 'status',
        header: t('invoices.invoice.status.title'),
        Cell: ({ row }) => <InvoiceStatus invoice={row.original} />,
      },
    ],
    [t],
  );
}
