import DownloadIcon from '@mui/icons-material/Download';
import { Box } from '@mui/material';
import { MRT_Row } from 'material-react-table';
import { InvoiceDTO } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const InvoiceActions = ({ row, isLoading }: { row: MRT_Row<InvoiceDTO>; isLoading: string }) => {
  const InvoiceButton: React.FC<{ color: string; text: string; invoiceId: string; showIcon?: boolean; disabled?: boolean }> = useCallback(
    ({ color, text, invoiceId, showIcon, disabled }) => {
      return (
        <Box p={0.5} my={0.5} display="flex">
          <LoadingButton
            size="small"
            loading={isLoading === invoiceId}
            fullWidth
            disabled={disabled}
            sx={{ px: 1, py: 0.5, borderRadius: 2, height: '100%', backgroundColor: color }}
            endIcon={showIcon && <DownloadIcon fontSize="small" />}
          >
            {text}
          </LoadingButton>
        </Box>
      );
    },
    [isLoading],
  );

  const { t } = useTranslation('tenantModule');
  if (row.original.accountingInvoiceNumber) {
    return <InvoiceButton color="primary.main" text={t('invoices.invoice.actions.download')} showIcon invoiceId={row.original.invoiceId} />;
  } else {
    return <InvoiceButton color="warning.main" text={t('invoices.invoice.actions.pending')} disabled invoiceId={row.original.invoiceId} />;
  }
};
