import { UseQueryResult } from '@tanstack/react-query';
import { ListInvoiceResponseDTO, TenantApiListInvoicesRequest } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';

export function useInvoices(params: Omit<TenantApiListInvoicesRequest, 'tenantId'>): UseQueryResult<ListInvoiceResponseDTO> {
  return useTenantQuery(
    ['invoices', params, apis.couponsV2.listCouponGifts.name],
    tenantId => apis.tenants.listInvoices({ tenantId, ...params }).then(res => res.data),
    {
      placeholderData: previousData => previousData,
    },
  );
}
