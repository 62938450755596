import { Button, SxProps, Tooltip, Typography } from '@mui/material';
import { resizeImage } from 'probonio-shared-ui/component/imageResizer/ImageResizer';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldPath, FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ImageFile } from '../imageFile/ImageFile';
import { useSnackbar } from 'notistack';

interface Props<T extends FieldValues, TName extends FieldPath<T> = FieldPath<T>> extends UseControllerProps<T, TName> {
  disabled?: boolean;
  label?: string;
  shouldCoverBox?: boolean;
  maxSize?: number;
  tooltip?: string;
  sx?: SxProps;
  dataTestId?: string;
}

export const SelectImageControl = <T extends FieldValues, TName extends FieldPath<T> = FieldPath<T>>({
  name,
  rules,
  control,
  defaultValue,
  shouldCoverBox,
  shouldUnregister,
  maxSize,
  disabled,
  label,
  tooltip,
  dataTestId,
  sx,
}: Props<T, TName>): JSX.Element => {
  const { t } = useTranslation('tenantModule');
  const { field, fieldState } = useController({ name, rules, control, defaultValue, shouldUnregister });
  const [imageUrl, setImageUrl] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();

  const image = (field.value as string | ImageFile | File | undefined) || defaultValue;

  useEffect(() => {
    if (typeof image === 'string' || !image) {
      setImageUrl(image);
      return;
    }
    if (typeof image === 'object' && image instanceof File) {
      const objectUrl = URL.createObjectURL(image);
      setImageUrl(objectUrl);

      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    } else if (image instanceof ImageFile) {
      void image.getBlob().then(value => {
        const objectUrl = URL.createObjectURL(value || defaultValue);
        setImageUrl(objectUrl);
        return () => {
          URL.revokeObjectURL(objectUrl);
        };
      });
    }
  }, [defaultValue, image]);

  const handleSelectFile = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    async ev => {
      if (!ev.target.files?.length) {
        return;
      }

      let newFile: ImageFile;
      const targetFile = ev.target.files[0];
      if (targetFile.type && !targetFile.type.startsWith('image')) {
        enqueueSnackbar(t('imageError'), { variant: 'warning' });
        return;
      }

      if (maxSize) {
        // resize image if required
        const originalImage = new ImageFile({ file: targetFile });
        const imageUri = await originalImage.getURI();
        const resizedFile = await resizeImage(imageUri, maxSize, targetFile.type);
        if (resizedFile.isDownscaled) {
          newFile = new ImageFile({ file: new File([resizedFile.blob], targetFile.name, { type: resizedFile.mimeType }) });
        } else {
          newFile = new ImageFile({ file: targetFile });
        }
      } else {
        newFile = new ImageFile({ file: targetFile });
      }

      if (newFile && maxSize) {
        field.onChange(newFile);
        field.onBlur();
      } else {
        field.onChange(targetFile);
        field.onBlur();
      }
    },
    [enqueueSnackbar, field, maxSize, t],
  );

  return (
    <>
      {label && (
        <Typography variant="body2" color="text.secondary">
          {label}
        </Typography>
      )}

      <Tooltip title={tooltip} arrow>
        <Button
          data-test-id={dataTestId}
          component="label"
          disabled={disabled}
          fullWidth
          sx={sx}
          variant="outlined"
          color={fieldState.error ? 'error' : 'primary'}
        >
          {!image && <Typography color="text.secondary">{t('common:noImageSelected')}</Typography>}

          <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handleSelectFile} />
          {image && (
            <img
              alt="image"
              src={imageUrl}
              style={{ height: '100%', width: '100%', objectFit: shouldCoverBox ? 'cover' : 'contain', display: 'block' }}
            />
          )}
        </Button>
      </Tooltip>
    </>
  );
};
