import { useSortable } from '@dnd-kit/sortable';
import DragHandle from '@mui/icons-material/DragHandle';
import { Box, Card, CardActionArea, CardHeader, SvgIconProps, Typography } from '@mui/material';
import React from 'react';
export interface DraggableCardProps<T> {
  id: string;
  title?: string;
  icon: React.ComponentType<SvgIconProps>;
  data: T;
}
export const DraggableCard = <T,>({
  id,
  title,
  icon, // Use destructuring to rename the icon component
  data,
}: DraggableCardProps<T>) => {
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id,
  });

  const Icon = icon;
  return (
    <Box
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      sx={{ cursor: isDragging ? 'grabbing' : 'grab' }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Card variant="outlined" style={{ width: '100%' }}>
        <CardActionArea component="div">
          <CardHeader
            action={<DragHandle style={{ marginTop: 3 }} />}
            avatar={<Icon color="primary" fontSize="large" />}
            sx={{ py: 1 }}
            title={
              <Typography color="primary.main" mb={0.5}>
                {title}
              </Typography>
            }
          />
        </CardActionArea>
      </Card>
    </Box>
  );
};
