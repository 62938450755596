import DownloadIcon from '@mui/icons-material/Download';
import HistoryIcon from '@mui/icons-material/History';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Alert, Box, Button, IconButton, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { BenefitDTOBenefitEnum, TenantDTOFlexBenefitsEnum } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { apis, downloadResponse, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useTenant, useTenantID } from 'probonio-shared-ui/module/me';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../../component/dialog';
import { DatePickerControl, SelectControl } from '../../../component/form';
import { PayrollExportHistoryModal } from './PayrollExportHistoryModal';
import { ExportTypeFields, useExportTypeFields } from './payrollReportConfig';
import { ExportDate } from './BenefitPayrollReportPage';
import { BENEFIT_ICONS } from '../BenefitIcon';

interface FormFields {
  exportMonth: Date;
  processingMonth: Date;
  exportRange: [Date, Date];
  exportEnd?: Date;
  benefits?: BenefitDTOBenefitEnum[];
  flexBenefitsToInclude?: BenefitDTOBenefitEnum[];
}

const BENEFITS_WITH_DATE_RANGE_ENABLED: BenefitDTOBenefitEnum[] = [BenefitDTOBenefitEnum.Gifts, BenefitDTOBenefitEnum.Coupons];

const LAST_MONTH = DateTime.now().minus({ months: 1 }).startOf('month');

const LastExportLabel: React.FC<{ benefits: BenefitDTOBenefitEnum[] }> = ({ benefits }) => {
  const { t } = useTranslation('benefitsModule');

  const { data: tenantExportHistory } = useTenantQuery(
    ['exportHistory', { page: 0, pageSize: 1, benefits }, apis.tenants.getTenantExportHistory.name],
    async tenantId =>
      apis.tenants
        .getTenantExportHistory({
          tenantId,
          page: 0,
          pageSize: 1,
          benefits,
        })
        .then(res => res.data),
  );

  const lastExportDateStr = tenantExportHistory?.results?.[0]?.createdAt;
  const lastExportDate = lastExportDateStr ? DateTime.fromISO(lastExportDateStr) : undefined;

  return lastExportDate ? (
    <Typography color="text.secondary" variant="body2">
      {t('report.lastExport', { date: lastExportDate.toJSDate() })}
    </Typography>
  ) : null;
};

function displayLogic(
  reportBenefit: BenefitDTOBenefitEnum,
  activeBenefits: BenefitDTOBenefitEnum[],
  flexBenefits: TenantDTOFlexBenefitsEnum[] = [],
  couponOrderDeadline?: number,
) {
  const isRangeAllowed = activeBenefits.every(benefit => BENEFITS_WITH_DATE_RANGE_ENABLED.includes(benefit));
  const showGiftAndCouponsFilter =
    reportBenefit === BenefitDTOBenefitEnum.Coupons &&
    activeBenefits.includes(BenefitDTOBenefitEnum.Coupons) &&
    activeBenefits.includes(BenefitDTOBenefitEnum.Gifts);
  const isFlex = reportBenefit === BenefitDTOBenefitEnum.Flex;

  let tenantFlexReportBenefits = [...flexBenefits];
  if (isFlex && tenantFlexReportBenefits.includes(BenefitDTOBenefitEnum.Coupons)) {
    tenantFlexReportBenefits.push(BenefitDTOBenefitEnum.Gifts);
  }
  if (couponOrderDeadline) {
    tenantFlexReportBenefits = tenantFlexReportBenefits.filter(benefit => benefit !== BenefitDTOBenefitEnum.Coupons);
  }

  const payrollHistoryBenefits = [...activeBenefits];
  if (payrollHistoryBenefits.every(benefit => benefit === BenefitDTOBenefitEnum.Flex && couponOrderDeadline)) {
    payrollHistoryBenefits.push(BenefitDTOBenefitEnum.Coupons);
  }
  return { isRangeAllowed, showGiftAndCouponsFilter, isFlex, tenantFlexReportBenefits, payrollHistoryBenefits };
}

export const PayrollDownloadPanel: React.FC<{
  reportBenefit: BenefitDTOBenefitEnum;
  activeBenefits: BenefitDTOBenefitEnum[];
  disabled?: boolean;
  onChangeExportMonth: (exportDate: ExportDate) => void;
  isInterval: boolean;
}> = ({ reportBenefit, activeBenefits, disabled, onChangeExportMonth, isInterval }) => {
  const { t } = useTranslation('receiptsModule');
  const { tenant } = useTenant();
  const tenantId = useTenantID();
  const withMessage = useWithMessage();
  const [exportSuccess, setExportSuccess] = useState(false);
  const [exportEmployeeNumberWarning, setExportEmployeeNumberWarning] = useState(false);
  const [errorAlertKey, setErrorAlertKey] = useState<string>();
  const exportHistoryDialog = useNewDialogState();

  const { isRangeAllowed, showGiftAndCouponsFilter, isFlex, tenantFlexReportBenefits, payrollHistoryBenefits } = displayLogic(
    reportBenefit,
    activeBenefits,
    tenant?.flexBenefits,
    tenant?.couponOrderDeadline,
  );

  const { setValue, control, handleSubmit, watch } = useForm<FormFields>({
    defaultValues: {
      exportMonth: LAST_MONTH.toJSDate(),
      exportRange: [DateTime.now().startOf('month').toJSDate(), DateTime.now().toJSDate()],
      processingMonth: LAST_MONTH.plus({ month: 1 }).toJSDate(),
      benefits: showGiftAndCouponsFilter ? [BenefitDTOBenefitEnum.Coupons, BenefitDTOBenefitEnum.Gifts] : [reportBenefit],
      flexBenefitsToInclude: isFlex ? tenantFlexReportBenefits : undefined,
    },
  });

  const exportMonth = watch('exportMonth');
  const [start, end] = watch('exportRange');

  const isRangeValid = useMemo(() => !isInterval || (start && !!end), [end, isInterval, start]);

  useEffect(() => {
    const processingMonth = isInterval ? new Date() : DateTime.fromJSDate(exportMonth).plus({ month: 1 }).toJSDate();

    setValue('processingMonth', processingMonth);
    onChangeExportMonth(isInterval ? { end, start } : exportMonth);
  }, [exportMonth, start, end, onChangeExportMonth, setValue, isInterval]);

  const correctDate = (date: string | Date, format: string): string => {
    if (typeof date === 'string') {
      return DateTime.fromISO(date).toFormat(format);
    }
    return DateTime.fromJSDate(date).toFormat(format);
  };

  const handleExportMonth = useCallback(() => onChangeExportMonth(exportMonth), [exportMonth, onChangeExportMonth]);
  const handleExportInterval = useCallback(() => {
    onChangeExportMonth({ end, start });
  }, [end, onChangeExportMonth, start]);

  const exportMutation = useMutation({
    mutationFn: async (values: FormFields) => {
      setExportSuccess(false);
      const exportDates = isInterval
        ? { start: correctDate(start, 'yyyy-MM-dd'), end: correctDate(end, 'yyyy-MM-dd') }
        : { filterMonth: correctDate(values.exportMonth, 'yyyy-MM') };

      const processingMonth = correctDate(values.processingMonth, 'yyyy-MM');

      const res = await apis.benefits.getPayrollReport({
        tenantId: tenantId(),
        statementMonth: processingMonth,
        benefits: !isFlex ? values.benefits : undefined,
        flexBenefitsToInclude: isFlex ? values.flexBenefitsToInclude : undefined,
        ...exportDates,
      });
      downloadResponse(res, contentType => {
        const extension = contentType?.includes('text/csv') ? 'csv' : 'txt';
        return `${tenant?.lodasExportType || 'export'}-${exportMonth}.${extension}`;
      });

      return res.headers['x-warning'] === 'missingEmployeeNumber';
    },

    onSuccess: (missingEmployeeNumber: boolean) => {
      setErrorAlertKey(undefined);
      setExportSuccess(true);
      setExportEmployeeNumberWarning(missingEmployeeNumber);
    },
    onError: err => {
      if ((err as AxiosError).response?.status === 422) {
        if (
          (
            err as AxiosError<{
              serviceMessage?: string;
            }>
          ).response?.data?.serviceMessage?.includes('employee number')
        ) {
          setErrorAlertKey('employeeNumbersMissing');
        } else if (
          (
            err as AxiosError<{
              serviceMessage?: string;
            }>
          ).response?.data?.serviceMessage?.includes('lunchSalaryReduction')
        ) {
          setErrorAlertKey('salaryConversionParameterMissing');
        } else if (
          (
            err as AxiosError<{
              serviceMessage?: string;
            }>
          ).response?.data?.serviceMessage?.includes('mobilityTaxable')
        ) {
          setErrorAlertKey('mobilityTaxableParameterMissing');
        } else if (
          (
            err as AxiosError<{
              serviceMessage?: string;
            }>
          ).response?.data?.serviceMessage?.includes('gifts')
        ) {
          setErrorAlertKey('giftsParameterMissing');
        } else if (
          (
            err as AxiosError<{
              serviceMessage?: string;
            }>
          ).response?.data?.serviceMessage?.includes('coupons_v2_incentives')
        ) {
          setErrorAlertKey('incentivesParameterMissing');
        } else {
          setErrorAlertKey('lodasParametersMissing');
        }
      } else {
        setErrorAlertKey(undefined);
        withMessage(err as Error);
      }
    },
  });

  const handleGetDatevExport = useCallback(
    (values: FormFields) => {
      exportMutation.mutate(values);
    },
    [exportMutation],
  );

  const exportTypeFields = useExportTypeFields(activeBenefits);

  const benefitMenuItems = (
    !showGiftAndCouponsFilter ? tenantFlexReportBenefits : [BenefitDTOBenefitEnum.Coupons, BenefitDTOBenefitEnum.Gifts]
  ).map(benefit => {
    const Icon = BENEFIT_ICONS[benefit];
    return (
      <MenuItem key={benefit} value={benefit}>
        <Box display="flex" gap={1} overflow="hidden" width="100%">
          <Icon key={benefit} color="primary" />
          {t(`common:benefit.${benefit}`)}
        </Box>
      </MenuItem>
    );
  });

  const handleRenderBenefits = useCallback(
    (selected: unknown) => {
      const benefits = selected as BenefitDTOBenefitEnum[];
      return benefits.length === 0 ? (
        <em>{t('common:pleaseSelect')}</em>
      ) : (
        benefits.map(benefit => t(`common:benefit.${benefit}`)).join(', ')
      );
    },
    [t],
  );

  return (
    <form onSubmit={handleSubmit(handleGetDatevExport)}>
      <Box display="flex" mt={1}>
        <Typography variant="h2" mb={2} flexGrow={1}>
          {t('downloadTitle')}
        </Typography>
      </Box>
      {exportSuccess && !exportEmployeeNumberWarning && (
        <Alert severity="success" sx={{ marginBottom: 1.5, whiteSpace: 'pre-line' }}>
          {t('benefitsModule:settingsDialog.exportSuccess')}
        </Alert>
      )}
      {exportSuccess && exportEmployeeNumberWarning && (
        <Alert severity="warning" sx={{ marginBottom: 1.5, whiteSpace: 'pre-line' }}>
          {t('benefitsModule:settingsDialog.exportEmployeeNumberWarning')}
        </Alert>
      )}
      {errorAlertKey && (
        <Alert severity="error" sx={{ marginBottom: 1 }}>
          {t(errorAlertKey)}
        </Alert>
      )}
      <Stack spacing={2}>
        {!isInterval || !isRangeAllowed ? (
          <Box>
            <Typography color="text.secondary" gutterBottom>
              {t('exportMonth')}
            </Typography>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <DatePickerControl
                monthYearPicker
                showNextPreviousToggle
                fullWidth
                control={control}
                rules={{ required: true }}
                name={ExportTypeFields.ExportMonth}
                label=""
                minDate={DateTime.now().minus({ year: 5 }).toJSDate()}
                maxDate={DateTime.now().endOf('month').toJSDate()}
              />
              {isRangeAllowed && (
                <Tooltip title={t('exportSwitchInterval')}>
                  <IconButton sx={{ marginLeft: 0.5 }} onClick={handleExportInterval}>
                    <SwapHorizIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        ) : (
          <>
            <Box>
              <Typography color="text.secondary" gutterBottom>
                {t('exportMonth')}
              </Typography>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <DatePickerControl
                  slotProps={{ input: { fullWidth: true } }}
                  selectsRange={true}
                  fullWidth
                  dateFormat="dd.MM.yyyy"
                  key="exportRange"
                  control={control}
                  rules={{ required: true }}
                  name="exportRange"
                  label=""
                  // todo
                  minDate={DateTime.now().startOf('month').toJSDate()}
                  maxDate={DateTime.now().toJSDate()}
                />
                <Tooltip onClick={handleExportMonth} title={t('exportSwitchMonth')}>
                  <IconButton sx={{ marginLeft: 0.5 }}>
                    <SwapHorizIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </>
        )}
        {exportTypeFields.includes(ExportTypeFields.ProcessingMonth) && (
          <Box>
            <Typography color="text.secondary" gutterBottom>
              {t('processingMonth')}
            </Typography>
            <DatePickerControl
              monthYearPicker
              showNextPreviousToggle
              fullWidth
              control={control}
              rules={{ required: true }}
              name={ExportTypeFields.ProcessingMonth}
              label=""
              helperText={t('processingMonthHelper')}
              minDate={DateTime.now().minus({ years: 1 }).toJSDate()}
              maxDate={DateTime.now().plus({ years: 1 }).toJSDate()}
            />
          </Box>
        )}
        {(exportTypeFields.includes(ExportTypeFields.FlexBenefitsToInclude) || showGiftAndCouponsFilter) && (
          <Box sx={{ '& label': { display: 'none' } }}>
            <Typography color="text.secondary" gutterBottom>
              {t('flexBenefitsToInclude')}
            </Typography>
            <SelectControl
              control={control}
              multiple
              name={showGiftAndCouponsFilter ? 'benefits' : 'flexBenefitsToInclude'}
              fullWidth
              rules={{ required: true }}
              displayEmpty
              renderValue={handleRenderBenefits}
            >
              {benefitMenuItems}
            </SelectControl>
          </Box>
        )}
        <LoadingButton
          type="submit"
          disableElevation
          disabled={!tenant?.lodasExportType || disabled || !isRangeValid}
          loading={exportMutation.isPending}
          title={t('downloadHint')}
          startIcon={<DownloadIcon />}
        >
          {t('download')}
        </LoadingButton>
        <Box>
          <Button variant="outlined" fullWidth onClick={exportHistoryDialog.handleOpen} startIcon={<HistoryIcon />}>
            {t('payrollExportHistoryModal.showHistory')}
          </Button>
        </Box>
      </Stack>
      <Box mt={1}>
        <LastExportLabel benefits={activeBenefits} />
      </Box>
      <PayrollExportHistoryModal dialogState={exportHistoryDialog.dialogState} benefit={payrollHistoryBenefits} />
    </form>
  );
};
