import { Alert, Box, SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { CouponGiftDTO, CouponGiftDTOOccasionEnum, UserDTOStatusEnum } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useTenantID } from 'probonio-shared-ui/module/me';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../../../component/dialog';
import { DialogLoadingButton } from '../../../../component/dialog/DialogLoadingButton';
import { DatePickerControl, SelectControl } from '../../../../component/form';
import { CheckboxControl } from '../../../../component/form/CheckboxControl';
import PlaceholderInput from '../../../../component/form/PlaceholderInput/PlaceholderInput';
import { Placeholder } from '../../../../component/form/PlaceholderInput/PlaceholderInput.types';
import { AvailableCouponsAccordion } from '../../coupons/AvailableCouponsDrawer';
import { GIFT_BUDGET_OPTIONS, GIFT_OCCASSION_OPTIONS, getNextBirthDay, giftYearlyAvailable } from '../../coupons/giftUtils';
import { useRefetchCouponGifts, useRefetchGiftOccasions } from '../../query';

export interface GiftFormValues {
  payoutDate: Date;
  occasion: CouponGiftDTOOccasionEnum;
  description: string;
  amount: number;
  recurring: boolean;
  inviteWhenActive: boolean;
  sendMailWhenActive: boolean;
}

interface Props {
  dialogState: DialogState;
  employeeId: string;
  currentGift?: CouponGiftDTO;
  birthDate?: string;
  userStatus?: UserDTOStatusEnum;
}

export const GiftsDialog: React.FC<Props> = ({ dialogState, employeeId, currentGift, birthDate, userStatus }) => {
  const { t, i18n } = useTranslation('benefitsModule');
  const { control, watch, handleSubmit, getValues, reset, setValue } = useForm<GiftFormValues>();
  const [descriptionPrefill, setDescriptionPrefill] = useState('');
  const getTenantId = useTenantID();
  const withMessage = useWithMessage((err: Error) => ((err as AxiosError).response?.status === 409 ? t('conflictError') : undefined));
  const { enqueueSnackbar } = useSnackbar();

  const refetchOccasions = useRefetchGiftOccasions();
  const refetchGifts = useRefetchCouponGifts(employeeId);

  const { data, isLoading } = useTenantQuery(['benefits', 'coupons', 'definition', apis.coupons.findCouponDefinitions.name], tenantId =>
    apis.coupons.findCouponDefinitions({ tenantId, sortBy: ['name:asc'] }).then(resp => resp.data),
  );
  const { data: occasions } = useTenantQuery(['benefits', 'coupons', 'giftOccasions'], tenantId =>
    apis.coupons.getGiftOccasions({ tenantId }).then(resp => resp.data),
  );
  const amount = watch('amount');
  const occasion = watch('occasion');

  const getDefaultDescriptionForOccasion = useCallback(
    (occasion: CouponGiftDTOOccasionEnum) => {
      const description = occasions?.giftOccasions
        .filter(giftOccasion => giftOccasion.occasion === occasion)
        .map(giftOccasion => giftOccasion.description)[0];
      return description ? description : t(`couponsModule:giftOccasions.description.${occasion}`);
    },
    [occasions?.giftOccasions, t],
  );

  //set values for Form
  useEffect(() => {
    if (dialogState.isOpen) {
      const occasion = currentGift?.occasion || CouponGiftDTOOccasionEnum.Birthday;
      const payoutDate = currentGift?.payoutDate
        ? DateTime.fromISO(currentGift?.payoutDate).toJSDate()
        : DateTime.now().endOf('day').toJSDate();
      const newPayoutDate =
        occasion === CouponGiftDTOOccasionEnum.Birthday && birthDate && !currentGift?.payoutDate
          ? getNextBirthDay(birthDate)?.toJSDate()
          : payoutDate;
      const description = currentGift?.description || getDefaultDescriptionForOccasion(occasion);
      reset({
        payoutDate: newPayoutDate,
        amount: currentGift?.amount || 6000,
        occasion,
        description,
        recurring: currentGift?.recurring || false,
        inviteWhenActive: currentGift?.inviteWhenActive || false,
        sendMailWhenActive: currentGift?.sendMailWhenActive || false,
      });
      setDescriptionPrefill(description);
    }
  }, [currentGift, dialogState.isOpen, reset, t, birthDate, getDefaultDescriptionForOccasion]);

  //set description depending on occasion
  const handleChangeOccasion = useCallback(
    (event: SelectChangeEvent<CouponGiftDTOOccasionEnum>) => {
      const description = getDefaultDescriptionForOccasion(event.target.value as CouponGiftDTOOccasionEnum);
      const birthDay = getNextBirthDay(birthDate)?.toJSDate();
      setValue('description', description);
      setDescriptionPrefill(description);
      if (birthDay && event.target.value === CouponGiftDTOOccasionEnum.Birthday) {
        setValue('payoutDate', birthDay);
      }
    },
    [getDefaultDescriptionForOccasion, birthDate, setValue],
  );

  const giftMutation = useMutation({
    mutationFn: async (gift: GiftFormValues) => {
      if (currentGift) {
        const newPayoutDate = DateTime.fromJSDate(gift.payoutDate).toFormat('yyyy-MM-dd');
        await apis.coupons.updateCouponGift({
          couponGiftId: currentGift.id,
          tenantId: getTenantId(),
          employeeId,
          updateCouponGiftDTO: {
            ...gift,
            payoutDate: currentGift.payoutDate.substring(0, 10) === newPayoutDate ? undefined : newPayoutDate,
            recurring: gift.recurring && giftYearlyAvailable(gift.occasion),
          },
        });
      } else {
        await apis.coupons.createCouponGift({
          tenantId: getTenantId(),
          employeeId,
          createCouponGiftDTO: {
            ...gift,
            payoutDate: DateTime.fromJSDate(gift.payoutDate).toFormat('yyyy-MM-dd'),
            recurring: gift.recurring && giftYearlyAvailable(gift.occasion),
          },
        });
      }
    },

    onSuccess: () => {
      void refetchGifts();
      void refetchOccasions();
      enqueueSnackbar(t(currentGift ? 'successUpdate' : 'successCreate'), { variant: 'success' });
      dialogState.handleClose();
    },
    onError: async err => {
      if (currentGift && err instanceof AxiosError && err.response?.status === 422) {
        enqueueSnackbar(t('couponsModule:giftAlreadyCompleted'), { variant: 'warning' });
        dialogState.handleClose();
        await refetchGifts();
      } else {
        withMessage(err as Error);
      }
    },
  });

  const handleSaveGift = useCallback(() => {
    const values = getValues();
    giftMutation.mutate(values);
  }, [getValues, giftMutation]);

  const handleChangeDescription = useCallback(
    (v: string) => {
      setValue('description', v);
    },
    [setValue],
  );

  const placeholders: Placeholder[] = useMemo(
    () => [
      {
        label: t('GIFTS.placeHolderInput.firstNameChipLabel'),
        value: 'firstName',
      },
      {
        label: t('GIFTS.placeHolderInput.lastNameChipLabel'),
        value: 'lastName',
      },
    ],
    [t],
  );

  return (
    <BasicDialog
      dialogState={dialogState}
      sx={{ overflow: 'visible' }}
      title={currentGift ? t('GIFTS.dialogEditTitle') : t('GIFTS.dialogCreateTitle')}
      content={
        <form onSubmit={handleSubmit(handleSaveGift)}>
          {(occasion === CouponGiftDTOOccasionEnum.CompanyAnniversary || occasion === CouponGiftDTOOccasionEnum.Other) && (
            <Alert severity="info" sx={{ mb: 2 }}>
              {t(`couponsModule:giftOccasions.info.${occasion}`)}
            </Alert>
          )}
          <Grid container gap={1} wrap="nowrap" mb={2}>
            <Grid size={5}>
              <DatePickerControl
                dateFormat="dd.MM.yyyy"
                minDate={DateTime.now().startOf('day').toJSDate()}
                maxDate={DateTime.now().plus({ years: 10 }).toJSDate()}
                control={control}
                name="payoutDate"
                label={t('fields.date')}
                fullWidth
                margin="dense"
                rules={{
                  required: true,
                }}
              />
            </Grid>
            <Grid size={2}>
              <SelectControl
                control={control}
                name="amount"
                label={t('GIFTS.fields.amount')}
                fullWidth
                margin="dense"
                rules={{ required: true }}
                options={GIFT_BUDGET_OPTIONS.map(n => ({ value: n, label: i18n.format(n, 'euro-float') }))}
              />
            </Grid>
            <Grid size={5}>
              <SelectControl
                control={control}
                name="occasion"
                label={t('GIFTS.fields.occasion')}
                fullWidth
                margin="dense"
                rules={{ required: true, onChange: handleChangeOccasion }}
                options={GIFT_OCCASSION_OPTIONS.map(n => ({ value: n, label: t(`couponsModule:giftOccasions.${n}`) }))}
              />
            </Grid>
          </Grid>
          <Box mb={1}>
            <PlaceholderInput
              label={t('GIFTS.placeHolderInput.inputLabel')}
              placeholders={placeholders}
              onChange={handleChangeDescription}
              initialValue={descriptionPrefill}
            />
          </Box>
          <Box mb={1}>
            {giftYearlyAvailable(watch('occasion')) && (
              <CheckboxControl
                control={control}
                name="recurring"
                label={t('GIFTS.fields.recurring')}
                disabled={!giftYearlyAvailable(watch('occasion'))}
              />
            )}
            {userStatus === 'CREATED' && (
              <CheckboxControl control={control} name="inviteWhenActive" label={t('GIFTS.fields.inviteWhenActive')} />
            )}
            <CheckboxControl control={control} name="sendMailWhenActive" label={t('GIFTS.fields.sendMailWhenActive')} />
          </Box>

          {!isLoading && amount && (
            <Box>
              <AvailableCouponsAccordion coupons={data?.coupons} amount={amount} />
            </Box>
          )}
        </form>
      }
      actions={
        <DialogLoadingButton loading={giftMutation.isPending} onClick={handleSubmit(handleSaveGift)} data-test-id="button-gift-submit">
          {currentGift ? t('common:buttons.save') : t('common:buttons.create')}
        </DialogLoadingButton>
      }
    />
  );
};
