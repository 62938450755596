import { Divider, Typography } from '@mui/material';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SettingsFormPanel } from '../../../component/settings/SettingsFormPanel';
import { useBenefitSorting } from './useBenefitSorting';
import { SettingsFormRow } from '../../../component/settings/SettingsFormRow';
import { useForm } from 'react-hook-form';
import { CreateTenantBenefitSortingDTO, TenantBenefitSortingDTO } from 'probonio-shared-ui/api';
import BenefitDragAndDropList from './BenefitDragAndDropList';
export enum BenefitSortingControl {
  sorting = 'sorting',
}

export interface FormValues {
  [BenefitSortingControl.sorting]: TenantBenefitSortingDTO[];
}
export const BenefitSortingPanel: React.FC = () => {
  const { t } = useTranslation('tenantModule');
  const { tenant } = useTenant();

  const { data: sorting, isFetching, createBenefitSorting } = useBenefitSorting(tenant!.id);
  const formDefaultValues = useMemo(
    () => ({
      [BenefitSortingControl.sorting]: sorting?.records,
    }),
    [sorting?.records],
  );

  const {
    setValue,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = useForm<FormValues>({
    defaultValues: formDefaultValues,
  });

  const currentSorting = watch(BenefitSortingControl.sorting);
  useEffect(() => {
    if (!isFetching) {
      reset(formDefaultValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const handleReset = useCallback(() => reset(formDefaultValues), [formDefaultValues, reset]);

  const handleDragEnd = (items: TenantBenefitSortingDTO[]) => {
    setValue(BenefitSortingControl.sorting, items, { shouldDirty: true });
  };
  const handleSave = useCallback(
    (values: FormValues) => {
      const createItems: CreateTenantBenefitSortingDTO[] = values[BenefitSortingControl.sorting].map(item => ({
        benefit: item.benefit,
        customBenefitId: item.customBenefitId,
      }));
      createBenefitSorting.mutate({ records: createItems });
    },
    [createBenefitSorting],
  );

  return (
    <SettingsFormPanel
      title={t('benefitSorting.title')}
      info={t('benefitSorting.info')}
      onSubmit={handleSubmit(handleSave)}
      onReset={handleReset}
      isDirty={isDirty}
    >
      <Divider sx={{ marginY: 3 }} />
      <SettingsFormRow
        title={t('benefitSorting.subTitle')}
        info={
          <Typography variant="body2" color="text.secondary">
            <Trans i18nKey="tenantModule:benefitSorting.subInfo" />
          </Typography>
        }
        descriptionWidth={6}
        settingsWidth={6}
        hideDivider
      >
        {currentSorting && <BenefitDragAndDropList benefitSorting={currentSorting} onChange={handleDragEnd} />}
      </SettingsFormRow>
    </SettingsFormPanel>
  );
};
