import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { CreateTenantBenefitSortingListDTO, TenantBenefitSortingListDTO } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { useTranslation } from 'react-i18next';

export const useBenefitSorting = (
  tenantId: string,
): {
  data: TenantBenefitSortingListDTO | undefined;
  createBenefitSorting: UseMutationResult<void, Error, CreateTenantBenefitSortingListDTO, unknown>;
  isFetching: boolean;
} => {
  const getTenantId = useTenantID();
  const queryClient = useQueryClient();
  const { t } = useTranslation('tenantModule');
  const withMessage = useWithMessage();

  const { data, isFetching } = useTenantQuery(['benefitSorting', apis.benefits.getBenefitSorting.name], tenantId =>
    apis.benefits
      .getBenefitSorting({
        tenantId,
      })
      .then(res => res.data),
  );
  const createBenefitSortingMutation = useMutation({
    mutationFn: async ({ records }: CreateTenantBenefitSortingListDTO) => {
      await apis.benefits.createBenefitSorting({
        tenantId,
        createTenantBenefitSortingListDTO: { records },
      });
      await queryClient.invalidateQueries({ queryKey: ['tenants', getTenantId(), 'benefitSorting', apis.benefits.getBenefitSorting.name] });
    },

    onSuccess: () => {
      enqueueSnackbar(t('benefitSorting.successToast'), { variant: 'success' });
    },
    onError: (err: Error) => withMessage(err as Error),
  });

  return { data, isFetching, createBenefitSorting: createBenefitSortingMutation };
};
