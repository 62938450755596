import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton } from '@mui/material';
import type { MRT_ColumnDef, MRT_Row, MRT_SortingState } from 'material-react-table';
import { BenefitDTOBenefitEnum, EmployeeDTO } from 'probonio-shared-ui/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteScrollReactTable } from '../../../component/table/InfiniteScrollReactTable';
import { RowActionsRenderer } from '../../../component/table/ThemedMaterialReactTable';
import { mapSortModel } from '../../../component/table/sortMapper';
import { useLoadMore } from '../../../component/table/useLoadMore';
import { RowSelectionState, useManualRowSelection } from '../../../component/table/useManualRowSelection';
import { useNavigateWithPrevPath } from '../../../util/useNavigateWithPrevPath';
import { usePaginatedEmployeeList } from '../../userManagement/usePaginatedEmployeeList';
import { useEmployeeBenefitColumns } from '../benefitColumns';

import { useEmployeeColumns } from './employeeColumns';
import { EmployeeFilter, mapEmployeeFilterToRequest } from './employeeFilterState';
import { useAdditionalBAVColumns } from '../bav';
import { useAdditionalBikeleasingColumns } from '../../benefits/bikeleasing/context/useAdditionalBikeleasingColumns';

export interface AdditionalEmployeeColumns {
  columns: MRT_ColumnDef<EmployeeDTO>[];
  loadAdditionalData?: (employees: EmployeeDTO[]) => void;
}
// Place Additional Employee Columns here to avoid Benefit Logic inside this Component
const ADDITIONAL_COLUMN_HOOKS: Partial<Record<BenefitDTOBenefitEnum, () => AdditionalEmployeeColumns>> = {
  BIKELEASING: useAdditionalBikeleasingColumns,
  BAV: useAdditionalBAVColumns,
};
interface Props {
  benefit: BenefitDTOBenefitEnum;
  customBenefitId?: string;
  filter?: EmployeeFilter;
  rowSelectionState: RowSelectionState;
}

export const EmployeeBenefitStatusList: React.FC<Props> = ({ benefit, customBenefitId, filter, rowSelectionState }) => {
  const { t } = useTranslation('benefitsModule');
  const navigate = useNavigateWithPrevPath();
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'name', desc: false }]);

  const baseColumns = useEmployeeColumns();
  const benefitColumns = useEmployeeBenefitColumns(benefit);
  const additionalColumns = ADDITIONAL_COLUMN_HOOKS[benefit]?.();

  const columns = useMemo((): MRT_ColumnDef<EmployeeDTO>[] => {
    return [...baseColumns, ...benefitColumns, ...(additionalColumns?.columns ?? [])];
  }, [additionalColumns, baseColumns, benefitColumns]);

  const queryResult = usePaginatedEmployeeList({
    sortBy: mapSortModel(columns, sorting),
    includeBenefits: true,
    considerFutureActivations: true,
    includeActivationFor: benefit,
    includeActivationForCustomBenefitId: customBenefitId,
    ...mapEmployeeFilterToRequest(filter),
    activeBenefit: filter?.activationState === 'ACTIVE' ? benefit : filter?.benefitFilter?.benefit,
    activeCustomBenefitId: filter?.activationState === 'ACTIVE' ? customBenefitId : filter?.benefitFilter?.customBenefitId,
    inactiveBenefit: filter?.activationState === 'INACTIVE' ? benefit : undefined,
    inactiveCustomBenefitId: filter?.activationState === 'INACTIVE' ? customBenefitId : undefined,
    excludeEmployeeStatus: ['INACTIVE'],
  });

  useEffect(() => {
    const employees = queryResult.data?.pages.flatMap(employeeList => employeeList.employees);
    if (employees) {
      additionalColumns?.loadAdditionalData?.(employees);
    }
  }, [additionalColumns, queryResult.data]);

  const isRowSelectable = useCallback(
    (employee: EmployeeDTO) => filter?.activationState === 'ACTIVE' || !employee.benefitActivation,
    [filter?.activationState],
  );
  const handleRowId = useCallback((employee: EmployeeDTO) => employee.id, []);

  const loadMoreProps = useLoadMore(queryResult, 'employees');
  const noFilterSet = !filter?.searchText && !filter?.department && !filter?.benefitFilter && !filter?.showActiveUser;

  const manualRowSelectionProps = useManualRowSelection(rowSelectionState, loadMoreProps.data, handleRowId, isRowSelectable);

  // navigate to user details page on ACTIVE tab, row selection otherwise
  const handleRowProps = useCallback(
    ({ row }: { row: MRT_Row<EmployeeDTO> }) => {
      const selectionProps = manualRowSelectionProps.muiTableBodyRowProps({ row });

      const handleRowClick = (event: unknown) => {
        selectionProps.onClick(event);
      };

      return {
        ...selectionProps,
        onClick: handleRowClick,
        // fixed height to prevent layout shift in loading state
        sx: { ...selectionProps.sx, height: 62 },
      };
    },
    [manualRowSelectionProps],
  );

  const handleGoToEmployee = useCallback(
    (employeeId: string) => (event: React.MouseEvent) => {
      event.preventDefault();
      navigate(`/employees/${employeeId}`, {
        state: { forceHistoryBack: true },
      });
    },
    [navigate],
  );

  const renderRowActions: RowActionsRenderer<EmployeeDTO> = useCallback(
    ({ row }) => {
      return (
        <IconButton data-test-id="go-to-employee-button" onClick={handleGoToEmployee(row.id)}>
          <OpenInNewIcon fontSize="small" />
        </IconButton>
      );
    },
    [handleGoToEmployee],
  );

  return (
    <InfiniteScrollReactTable
      {...loadMoreProps}
      {...manualRowSelectionProps}
      columns={columns}
      enablePagination={false}
      enableTopToolbar={false}
      enableBottomToolbar={false}
      enableColumnActions={false}
      enableSortingRemoval={false}
      enableRowSelection
      enableRowActions
      positionActionsColumn="last"
      renderRowActions={renderRowActions}
      manualSorting
      onSortingChange={setSorting}
      state={useMemo(() => ({ sorting, ...manualRowSelectionProps.state }), [manualRowSelectionProps.state, sorting])}
      muiTableBodyRowProps={handleRowProps}
      localization={
        filter?.activationState === 'ACTIVE' && noFilterSet ? { noRecordsToDisplay: t('employeeTabs.noRecordsToDisplay') } : undefined
      }
      defaultColumn={useMemo(() => ({ minSize: 40, maxSize: 1000, size: 150 }), [])}
    />
  );
};
