import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { MRT_Row, MRT_SortingState } from 'material-react-table';
import { InvoiceDTO } from 'probonio-shared-ui/api';
import { apis, downloadFile } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDatePicker } from '../../component/datePicker';
import { ThemedMaterialReactTable } from '../../component/table/ThemedMaterialReactTable';
import { useInvoices } from '../invoice/invoiceQueries';
import { useInvoiceColumns } from '../invoice/useInvoiceColumns';
import { InvoiceActions } from '../invoice/useInvoiceRowActions';

export const InvoicePanel: React.FC = () => {
  const { t } = useTranslation('tenantModule');
  const { tenant } = useTenant();
  const [date, setDate] = useState(DateTime.now().toJSDate());
  const { data } = useInvoices({ invoiceYear: date.toString() });
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'name', desc: false }]);
  const [isLoading, setIsLoading] = useState('');

  const { mutate } = useMutation({
    mutationFn: async ({ invoiceId, date }: { invoiceId: string; date: string }) => {
      if (!tenant?.id) {
        return;
      }
      setIsLoading(invoiceId);
      const file = await apis.tenants
        .getInvoicePdf(
          {
            tenantId: tenant?.id,
            invoiceId,
          },
          {
            responseType: 'blob',
          },
        )
        .then(res => res.data);
      setIsLoading('');
      downloadFile(t('invoices.downloadFile', { date }), file, 'application/pdf');
    },
  });

  const handleInvoice = useCallback(
    (invoiceId: string, date: string) => {
      mutate({ invoiceId, date });
    },
    [mutate],
  );

  const COLUMNS = useInvoiceColumns();

  const handleRowProps = useCallback(
    ({ row }: { row: MRT_Row<InvoiceDTO> }) => {
      const handleRowClick = () => {
        handleInvoice(
          row.original.invoiceId,
          row.original.date ? DateTime.fromISO(row.original.date).toLocaleString() : DateTime.now().toLocaleString(),
        );
      };
      const handleEnterRowKeyEvent = (event: React.KeyboardEvent) => {
        // We cant fully typecast as the mui props expect an unknown event
        if (event.key === 'Enter') {
          handleRowClick();
        }
      };

      return row.getIsGrouped() || !row.original.accountingInvoiceNumber
        ? {}
        : {
            onClick: handleRowClick,
            onKeyDown: handleEnterRowKeyEvent,
            sx: { cursor: 'pointer' },
          };
    },
    [handleInvoice],
  );

  const handleRenderActions = useCallback(
    ({ row }: { row: MRT_Row<InvoiceDTO> }) => {
      return <InvoiceActions row={row} isLoading={isLoading} />;
    },
    [isLoading],
  );

  const handleDateSwitch = useCallback((newDate: Date | null) => {
    if (!newDate) {
      return;
    }
    setDate(newDate);
  }, []);
  return (
    <>
      <Box marginBottom={1.5} justifySelf="end">
        <CustomDatePicker
          minDate={tenant ? DateTime.fromISO(tenant.createdAt).endOf('year').toJSDate() : DateTime.now().minus({ years: 5 }).toJSDate()}
          maxDate={DateTime.now().startOf('year').toJSDate()}
          onChange={handleDateSwitch}
          value={date}
          dateFormat="yyyy"
          showYearPicker
          showNextPreviousToggle
          hideCalendarIcon
          slotProps={{ input: { size: 'small', sx: { flexShrink: 1 } } }}
        />
      </Box>
      <ThemedMaterialReactTable
        data={data?.invoices || []}
        columns={COLUMNS}
        enablePagination={false}
        enableBottomToolbar={false}
        enableTopToolbar={false}
        enableColumnActions={false}
        enableSortingRemoval={false}
        state={{ sorting }}
        onSortingChange={setSorting}
        muiTableBodyRowProps={handleRowProps}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={handleRenderActions}
      />
    </>
  );
};
