import { MRT_Row } from 'material-react-table';
import { EmployeeDTO } from 'probonio-shared-ui/api';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';
import { InfiniteScrollReactTable } from '../../component/table/InfiniteScrollReactTable';
import { useLoadMore } from '../../component/table/useLoadMore';
import { useNavigateWithPrevPath } from '../../util/useNavigateWithPrevPath';
import { EmployeeFilter, mapEmployeeFilterToRequest } from '../benefits/employeeStatusList/employeeFilterState';
import { usePaginatedEmployeeList } from '../userManagement/usePaginatedEmployeeList';
import { useDashboardEmployeeColumns } from './dashboardEmployeeColumns';

interface Props {
  filter?: EmployeeFilter;
}

const DashboardEmployeeListComp: React.FC<Props> = ({ filter }) => {
  const { t } = useTranslation('dashboardModule');

  const navigate = useNavigateWithPrevPath();
  const columns = useDashboardEmployeeColumns();

  const queryResult = usePaginatedEmployeeList(
    {
      ...mapEmployeeFilterToRequest(filter),
      sortBy: ['createdAt:desc'],
      includeBenefits: true,
    },
    { enabled: columns !== undefined },
  );

  const loadMoreProps = useLoadMore(queryResult, 'employees');

  const handleRowProps = useCallback(
    ({ row }: { row: MRT_Row<EmployeeDTO> }) => {
      const handleRowClick = (event: unknown) => {
        navigate(`/employees/${row.original.id}`);
      };
      const handleEnterRowKeyEvent = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
          handleRowClick(event);
        }
      };

      return {
        onClick: handleRowClick,
        onKeyDown: handleEnterRowKeyEvent,
        sx: { cursor: 'pointer' },
      };
    },
    [navigate],
  );

  return (
    <>
      <SimpleBar style={{ flexGrow: 1, minHeight: 0 }}>
        <InfiniteScrollReactTable
          {...loadMoreProps}
          columns={columns}
          enablePagination={false}
          enableTopToolbar={false}
          enableBottomToolbar={false}
          enableColumnActions={false}
          enableSortingRemoval={false}
          manualSorting
          muiTableBodyRowProps={handleRowProps}
          localization={{ noRecordsToDisplay: t('usersModule:usersList.noResults') }}
          defaultColumn={{ minSize: 40, maxSize: 1000, size: 150 }}
        />
      </SimpleBar>
    </>
  );
};

export const DashboardEmployeeList = React.memo(DashboardEmployeeListComp);
